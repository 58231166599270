function timezoneList() {

  const list = [
    ["Africa/Algiers", "Africa/Algiers"],
    ["Africa/Cairo", "Africa/Cairo"],
    ["Africa/Casablanca", "Africa/Casablanca"],
    ["Africa/Harare", "Africa/Harare"],
    ["Africa/Johannesburg", "Africa/Johannesburg"],
    ["Africa/Monrovia", "Africa/Monrovia"],
    ["Africa/Nairobi", "Africa/Nairobi"],
    ["America/Argentina/Buenos_Aires", "America/Argentina/Buenos_Aires"],
    ["America/Bogota", "America/Bogota"],
    ["America/Caracas", "America/Caracas"],
    ["America/Chicago", "America/Chicago - Central Time (US & Canada)"],
    ["America/Denver", "America/Denver - Mountain Time (US & Canada)"],
    ["America/Godthab", "America/Godthab"],
    ["America/Guatemala", "America/Guatemala"],
    ["America/Guyana", "America/Guyana"],
    ["America/Halifax", "America/Halifax"],
    ["America/Indiana/Indianapolis", "America/Indiana/Indianapolis"],
    ["America/Juneau", "America/Juneau"],
    ["America/La_Paz", "America/La_Paz"],
    ["America/Lima", "America/Lima"],
    ["America/Los_Angeles", "America/Los_Angeles - Pacific Time (US & Canada)"],
    ["America/Mazatlan", "America/Mazatlan"],
    ["America/Mexico_City", "America/Mexico_City"],
    ["America/Monterrey", "America/Monterrey"],
    ["America/Montevideo", "America/Montevideo"],
    ["America/New_York", "America/New_York - Eastern Time (US & Canada)"],
    ["America/Phoenix", "America/Phoenix"],
    ["America/Regina", "America/Regina"],
    ["America/Santiago", "America/Santiago"],
    ["America/Sao_Paulo", "America/Sao_Paulo"],
    ["America/St_Johns", "America/St_Johns"],
    ["America/Tijuana", "America/Tijuana"],
    ["Asia/Almaty", "Asia/Almaty"],
    ["Asia/Baghdad", "Asia/Baghdad"],
    ["Asia/Baku", "Asia/Baku"],
    ["Asia/Bangkok", "Asia/Bangkok"],
    ["Asia/Chongqing", "Asia/Chongqing"],
    ["Asia/Colombo", "Asia/Colombo"],
    ["Asia/Dhaka", "Asia/Dhaka"],
    ["Asia/Hong_Kong", "Asia/Hong_Kong"],
    ["Asia/Irkutsk", "Asia/Irkutsk"],
    ["Asia/Jakarta", "Asia/Jakarta"],
    ["Asia/Jerusalem", "Asia/Jerusalem"],
    ["Asia/Kabul", "Asia/Kabul"],
    ["Asia/Kamchatka", "Asia/Kamchatka"],
    ["Asia/Karachi", "Asia/Karachi"],
    ["Asia/Kathmandu", "Asia/Kathmandu"],
    ["Asia/Kolkata", "Asia/Kolkata"],
    ["Asia/Krasnoyarsk", "Asia/Krasnoyarsk"],
    ["Asia/Kuala_Lumpur", "Asia/Kuala_Lumpur"],
    ["Asia/Kuwait", "Asia/Kuwait"],
    ["Asia/Magadan", "Asia/Magadan"],
    ["Asia/Muscat", "Asia/Muscat"],
    ["Asia/Novosibirsk", "Asia/Novosibirsk"],
    ["Asia/Rangoon", "Asia/Rangoon"],
    ["Asia/Riyadh", "Asia/Riyadh"],
    ["Asia/Seoul", "Asia/Seoul"],
    ["Asia/Shanghai", "Asia/Shanghai"],
    ["Asia/Singapore", "Asia/Singapore"],
    ["Asia/Taipei", "Asia/Taipei"],
    ["Asia/Tashkent", "Asia/Tashkent"],
    ["Asia/Tbilisi", "Asia/Tbilisi"],
    ["Asia/Tehran", "Asia/Tehran"],
    ["Asia/Tokyo", "Asia/Tokyo"],
    ["Asia/Ulaanbaatar", "Asia/Ulaanbaatar"],
    ["Asia/Urumqi", "Asia/Urumqi"],
    ["Asia/Vladivostok", "Asia/Vladivostok"],
    ["Asia/Yakutsk", "Asia/Yakutsk"],
    ["Asia/Yekaterinburg", "Asia/Yekaterinburg"],
    ["Asia/Yerevan", "Asia/Yerevan"],
    ["Atlantic/Azores", "Atlantic/Azores"],
    ["Atlantic/Cape_Verde", "Atlantic/Cape_Verde"],
    ["Atlantic/South_Georgia", "Atlantic/South_Georgia"],
    ["Australia/Adelaide", "Australia/Adelaide"],
    ["Australia/Brisbane", "Australia/Brisbane"],
    ["Australia/Darwin", "Australia/Darwin"],
    ["Australia/Hobart", "Australia/Hobart"],
    ["Australia/Melbourne", "Australia/Melbourne"],
    ["Australia/Perth", "Australia/Perth"],
    ["Australia/Sydney", "Australia/Sydney"],
    ["Etc/UTC", "Etc/UTC"],
    ["Europe/Amsterdam", "Europe/Amsterdam"],
    ["Europe/Athens", "Europe/Athens"],
    ["Europe/Belgrade", "Europe/Belgrade"],
    ["Europe/Berlin", "Europe/Berlin"],
    ["Europe/Bratislava", "Europe/Bratislava"],
    ["Europe/Brussels", "Europe/Brussels"],
    ["Europe/Bucharest", "Europe/Bucharest"],
    ["Europe/Budapest", "Europe/Budapest"],
    ["Europe/Copenhagen", "Europe/Copenhagen"],
    ["Europe/Dublin", "Europe/Dublin"],
    ["Europe/Helsinki", "Europe/Helsinki"],
    ["Europe/Istanbul", "Europe/Istanbul"],
    ["Europe/Kiev", "Europe/Kiev"],
    ["Europe/Lisbon", "Europe/Lisbon"],
    ["Europe/Ljubljana", "Europe/Ljubljana"],
    ["Europe/London", "Europe/London"],
    ["Europe/Madrid", "Europe/Madrid"],
    ["Europe/Minsk", "Europe/Minsk"],
    ["Europe/Moscow", "Europe/Moscow"],
    ["Europe/Paris", "Europe/Paris"],
    ["Europe/Prague", "Europe/Prague"],
    ["Europe/Riga", "Europe/Riga"],
    ["Europe/Rome", "Europe/Rome"],
    ["Europe/Sarajevo", "Europe/Sarajevo"],
    ["Europe/Skopje", "Europe/Skopje"],
    ["Europe/Sofia", "Europe/Sofia"],
    ["Europe/Stockholm", "Europe/Stockholm"],
    ["Europe/Tallinn", "Europe/Tallinn"],
    ["Europe/Vienna", "Europe/Vienna"],
    ["Europe/Vilnius", "Europe/Vilnius"],
    ["Europe/Warsaw", "Europe/Warsaw"],
    ["Europe/Zagreb", "Europe/Zagreb"],
    ["Pacific/Apia", "Pacific/Apia"],
    ["Pacific/Auckland", "Pacific/Auckland"],
    ["Pacific/Chatham", "Pacific/Chatham"],
    ["Pacific/Fakaofo", "Pacific/Fakaofo"],
    ["Pacific/Fiji", "Pacific/Fiji"],
    ["Pacific/Guadalcanal", "Pacific/Guadalcanal"],
    ["Pacific/Guam", "Pacific/Guam"],
    ["Pacific/Honolulu", "Pacific/Honolulu"],
    ["Pacific/Majuro", "Pacific/Majuro"],
    ["Pacific/Midway", "Pacific/Midway"],
    ["Pacific/Noumea", "Pacific/Noumea"],
    ["Pacific/Pago_Pago", "Pacific/Pago_Pago"],
    ["Pacific/Port_Moresby", "Pacific/Port_Moresby"],
    ["Pacific/Tongatapu", "Pacific/Tongatapu"],
  ];

  return list;
}

export default timezoneList;