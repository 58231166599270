import axios from "axios";
import { getToken } from "./tokenHelpers";

let apiBase;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  apiBase = "http://localhost:3001/api/";
} else {
  apiBase = "https://daily-standup.herokuapp.com/api/";
}

export const apiBaseUrl = apiBase;


axios.defaults.baseURL = apiBaseUrl;


// const setAuthHeader = () => ({
//   headers: { authorization: `Bearer ${getToken()}` }
// });

/// ---- old stuff above


const setAuthHeader = (token) => ({
  headers: { authorization: `Bearer ${token}` }
});

const setAuthHeaderForm = (token) => ({
  headers: { authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" }
});

export async function authenticatedGet(route) {
  const token = await getToken();
  const data = await axios.get(route, setAuthHeader(token));
  return data;
}

export async function authenticatedPost(route, payload) {
  const token = await getToken();
  const data = await axios.post(route, payload, setAuthHeader(token));
  return data;
}

export async function authenticatedPut(route, payload) {
  const token = await getToken();
  const data = await axios.put(route, payload, setAuthHeader(token));
  return data;
}

export async function authenticatedPostForm(route, payload) {
  const token = await getToken();
  const data = await axios.post(route, payload, setAuthHeaderForm(token));
  return data;
}


export const register = payload => axios.post(`/users`, payload);
export const login = payload => axios.post(`/users/login`, payload);
export const forgotpassword = payload => axios.post(`/users/send_password_reset_email`, payload);

export const resetPasswordEmail = payload => axios.post(`/users/send_password_reset_email`, payload);
export const resetPassword = payload => axios.post(`/users/reset_password`, payload);


export const getProfile = () => authenticatedGet(`users/profile`);
export const updateUser = (payload) => authenticatedPut(`users/update`, payload);

export const getDashboard = () => authenticatedGet(`users/dashboard`);
export const updateUserTask = (payload) => authenticatedPost('users/update_user_task', payload);
export const getLabels = () => authenticatedGet('users/get_labels');
export const addLabel = (payload) => authenticatedPost('users/add_label', payload);

export const getSoon = () => authenticatedGet(`users/soon`);
export const updateSoon = (payload) => authenticatedPost('users/update_soon', payload);

export const getSomeday = () => authenticatedGet(`users/someday`);
export const updateSomeday = (payload) => authenticatedPost('users/update_someday', payload);

export const getHistory = (payload) => authenticatedPost(`users/history`, payload);