import { useState, useEffect, useRef } from "react";


function EditMenu(props) {

  // Define variables
  const ref = useRef(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const openEditModal = props.openEditModal;
  const handleNextDayPush = props.handleNextDayPush;
  const handleDeletePush = props.handleDeletePush;
  const canPushToNextDay = props.canPushToNextDay;
  const canDeleteItem = props.canDeleteItem;
  const closeMenu = props.closeMenu;
  const bodyLength = props.bodyLength;
  const today = props.today;
  const pushText = today ? 'Push to tomorrow' : 'Push to next day';


  // Component load actions
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);


  // Define functions
  function handleClickOutside(event) {
    let canClose = true;

    const target = event.target;
    if( target?.className === 'delete-button' ) {
      canClose = false;
    }

    if( canClose ) {
      closeMenu();
    }
  }

  function handleDeleteInitial() {
    if( bodyLength === 0 ) {
      handleDeletePush();
    } else {
      setDeleteConfirm(true);
    }
  }


  // Screen components
  let pushToNextDayElement;
  if( canPushToNextDay ) {
    pushToNextDayElement = (
      <button
        type='button'
        class='push-to-next-day'
        title='Push item to next day'
        onClick={() => handleNextDayPush()}
      >{pushText}</button>
    );
  }

  let deleteItemElement;
  if( canDeleteItem ) {

    let deleteConfirmElement;
    if( deleteConfirm ) {
      deleteConfirmElement = (
        <button
          type='button'
          class='confirm-button'
          title='Click to confirm delete'
          onClick={() => handleDeletePush()}
        >Confirm?</button>
      );
    }

    deleteItemElement = (
      <div class='flex-row delete-button-container'>
        <button
          type='button'
          class='delete-button'
          title='Delete this item'
          onClick={() => handleDeleteInitial()}
        >Delete</button>
        {deleteConfirmElement}
      </div>
    );
  }


  return (
    <div class='edit-button-menu' ref={ref}>
      {pushToNextDayElement}
      {/* <button>Duplicate to tomorrow</button> */}
      {/* <button>Add sub-task</button> */}

      <button onClick={() => openEditModal()}>Add/edit details</button>

      {deleteItemElement}
    </div>
  );
}

export default EditMenu;