import { useState, useEffect } from 'react';
import chroma from 'chroma-js';
import CreatableSelect from 'react-select/creatable';

import apiCall from '../utilities/auth/apiCall';
import { getLabels, addLabel } from './auth/authApi';

const defaultLabelOptions = [
  { value: 'Work', label: 'Work', color: '#1037C2' },
  { value: 'Personal', label: 'Personal', color: '#7410C2' }
];

const moreColors = ['#03B9A3', '#1098C2', '#7E9D00', '#C28610'];
const defaultcolor = '#5C7D87';


function SelectLabels(props) {

  // Define variables
  const [labelOptions, setLabelOptions] = useState([]);
  const labels = props.labels;
  const setLabels = props.setLabels;
  const handleChange = props.handleChange;


  // Component load actions
  useEffect(() => {

    apiCall(getLabels).then((response) => {
      if( response.success ) {
        const theseLabelOptions = defaultLabelOptions.concat(response.data);
        setLabelOptions(theseLabelOptions);
      } else {
        setLabelOptions(defaultLabelOptions);
      }
    });

    // TO DO - get label options from server or set up redux
  }, []);


  // Define functions
  function handleChangeInit(method, addedLabels, name) {

    const existingColorsProps = labels.map((l) => l.color);
    const existingColorsOptions = labelOptions.map((l) => l.color);
    const existingColors = existingColorsProps.concat(existingColorsOptions);

    const possibleColors = moreColors.filter((element) => !existingColors.includes(element));

    addedLabels.map((label, index) => {
      if( label.__isNew__ ) {
        delete label.__isNew__;
        let color = defaultcolor;
        if( possibleColors[0] ) {
          color = possibleColors[0];
        }

        label.color = color;

        createNewLabel(label);
      }
    });


    handleChange(method, addedLabels, name);
  }

  function createNewLabel(label) {
    apiCall(addLabel, {label: label}).then((response) => {
      // Do nothing
    });
  }


  return (
    <div class='edit-row-item' style={{flexGrow: 1}}>
      <label>Labels</label>
      <CreatableSelect
        isMulti
        closeMenuOnSelect={false}
        value={labels}
        options={labelOptions}
        onChange={(addedLabels) => handleChangeInit(setLabels, addedLabels, 'labels')}
        styles={colourStyles}
      />
    </div>
  );
}

export default SelectLabels;






const colourStyles = {

  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = data.color ? chroma(data.color) : chroma(defaultcolor);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = data.color ? chroma(data.color) : chroma(defaultcolor);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => {
    const color = data.color ? chroma(data.color) : chroma(defaultcolor);
    return {
      ...styles,
      color: color.darken(1)
    };
  },
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
};