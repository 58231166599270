import { useState } from "react";

import { IoCloseCircle, IoChevronUpOutline, IoChevronDownOutline } from "react-icons/io5";
import handleSearchDashboard from "./handleSearchDashboard";
import handleSearchSoon from "./handleSearchSoon";
let searchResultsArray = [];


function SearchBar(props) {

  // Define variables
  const [activeSearch, setActiveSearch] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [searchResultsCount, setSearchResultsCount] = useState(0);
  const [currentSearchResultIndex, setCurrentSearchResultIndex] = useState(null);
  const searchType = props.searchType;
  const itemsList = props.itemsList;

  let searchFunction;
  if( searchType === 'dashboard' ) {
    searchFunction = handleSearchDashboard;
  } else if( searchType === 'soon' ) {
    searchFunction = handleSearchSoon;
  }

  // Define functions
  function handleClear() {
    setSearchResultsCount(0);
    setSearchString('');
    setActiveSearch(false);
    clearHighlights();
  }

  function clearHighlights() {
    Array.from(document.querySelectorAll('.day-item-container')).forEach(function(element) {
      element.classList.remove('highlight');
    });
  }

  function handleSearch(value) {
    setSearchString(value);
    if( searchString.length > 0 ) { setActiveSearch(true) }

    const searchResults = searchFunction(itemsList, value);
    searchResultsArray = searchResults;

    setSearchResultsCount(searchResults.length);
    hightResults(searchResults);
  }

  function hightResults(searchResults) {

    let firstElementId;

    searchResults?.reverse().map((id) => {
      const element = document.querySelector(`#${id}`);
      if( element ) {
        element.classList.add('highlight');
        firstElementId = id;
      }
    });

    // Scroll to the first item
    if( firstElementId ) {
      scrollToItem(firstElementId);
      setCurrentSearchResultIndex(0);
    }
  }

  function nextItem() {
    const nextIndex = currentSearchResultIndex + 1;
    const length = searchResultsArray.length - 1;
    const foundId = searchResultsArray[length - nextIndex];

    if( foundId ) {
      scrollToItem(foundId);
      setCurrentSearchResultIndex(nextIndex);
    }
  }

  function previousItem() {
    const previousIndex = currentSearchResultIndex - 1;
    const length = searchResultsArray.length - 1;
    const foundId = searchResultsArray[length - previousIndex];

    if( foundId ) {
      scrollToItem(foundId);
      setCurrentSearchResultIndex(previousIndex);
    }
  }

  function scrollToItem(id) {
    if( id ) {
      const element = document.getElementById(id);
      if( element ) {

        Array.from(document.querySelectorAll('.day-item-container.highlight-active')).forEach(function(element) {
          element.classList.remove('highlight-active');
        });

        element.classList.add('highlight-active');

        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    }
  }


  // Screen components
  let clearElement;
  if( activeSearch ) {
    clearElement = (
      <button
        title='Clear search'
        onClick={() => handleClear()}
        class='search-clear-button'
      >
        <IoCloseCircle size='20px' />
      </button>
    );
  }

  let foundItemsElement;
  if( searchResultsCount === 1 ) {

    foundItemsElement = (
      <div class='flex-row found-items-count-container'>
        {searchResultsCount} result

        <div class='flex-row found-items-button-container inactive'>
          <button>
            <div><IoChevronUpOutline size='16px' /></div>
          </button>

          <button>
            <div><IoChevronDownOutline size='16px' /></div>
          </button>
        </div>
      </div>
    );

  } else if( searchResultsCount > 1 ) {

    foundItemsElement = (
      <div class='flex-row found-items-count-container'>
        {searchResultsCount} results

        <div class='flex-row found-items-button-container'>
          <button
            onClick={() => previousItem()}
          >
            <div><IoChevronUpOutline size='16px' /></div>
          </button>

          <button
            onClick={() => nextItem()}
          >
            <div><IoChevronDownOutline size='16px' /></div>
          </button>
        </div>

      </div>
    );

  }


  return (
    <div class='serach-bar-inner'>
      <input
        type='text'
        placeholder='Search for a task'
        class='input-search'
        onChange={(event) => handleSearch(event.target.value)}
        value={searchString}
      />
      {clearElement}
      {foundItemsElement}
    </div>
  );
}

export default SearchBar;