import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import apiCall from '../utilities/auth/apiCall';
import { resetPassword } from '../utilities/auth/authApi';
import { isAuthed } from "../utilities/auth/tokenHelpers";
import FormErrorMessage from '../utilities/formErrorMessage';


function ResetPassword() {

  // Define variables
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get('reset_password_token');


  // Component load actions
  useEffect(() => {

    // Redirect if already logged in
    const isLoggedIn = isAuthed();
    if( isLoggedIn ) {
      navigate("/dashboard");
    }

  }, []);

  // Define functions
  function handleFormSubmit(event) {
    event.preventDefault();
    setButtonDisabled(true);

    const payload = {
      token: token,
      password: password
    }

    apiCall(resetPassword, payload).then((response) => {
      if( response.success ) {
        setSuccessMessage('Password reset successfully, you can now login.');
      } else {
        setErrorMessage(response.error);
        setButtonDisabled(false);
      }
    });

  }


  // Screen components
  let activeElement = (
    <form class='form' onSubmit={(event) => handleFormSubmit(event)}>
      <div class='input-label'>
      <input
          type='password'
          onChange={(event) => setPassword(event.target.value)}
          value={password}
          placeholder='Password'
          minLength={8}
          required
        />
      </div>

      <div class='input-label'>
        <button
          type='submit'
          class='button'
          disabled={buttonDisabled}
        >Reset Password</button>
      </div>
    </form>
  );

  if( successMessage ) {
    activeElement = (
      <p>{successMessage}</p>
    );
  }


  return (
    <div class='flex-column' style={{height: '100vh'}}>

      <header class='flex-row'>
        <div class='logo-square'></div>
        <h1>Dia Daily Standup</h1>
      </header>

      <section class='body-content'>

        <h1>Reset Your Password</h1>

        <FormErrorMessage message={errorMessage} />

        <div class='active-element-container'>
          {activeElement}
        </div>

        <br /><br /><br />
        <Link to='/login' class='sub-text'>Login</Link>
        <span>&nbsp;&nbsp;</span>|<span>&nbsp;&nbsp;</span>
        <Link to='/register' class='sub-text'>Register</Link>
      </section>
    </div>
  );
}

export default ResetPassword;