function SettingField(props) {

  // Define variables
  const displayName = props.displayName;
  const fieldName = props.fieldName;
  const inputType = props.inputType || 'text';
  const value = props.value;
  const handleChange = props.handleChange || undefined;
  const disabled = props.disabled || false;


  return (
    <div class='flex-row settings-field-container'>
      <div>{displayName}</div>
      <input
        type={inputType}
        value={value}
        onChange={(event) => handleChange(event.target.value)}
        disabled={disabled}
        class='input-basic'
      />
    </div>
  );
}

export default SettingField;