import { useState } from 'react';
import { Link } from 'react-router-dom';
import { IoHelpCircleOutline } from "react-icons/io5";

import HelpModal from '../helpModal';


function LeftColumn() {

  // Define variables
  const [helpModal, setHelpModal] = useState(null);
  const pathName = window.location.pathname;


  // Define functions
  function logout(event) {
    event.preventDefault();

    localStorage.removeItem("jwt-token-ds");
    window.location = '/';
  }

  function openHelpModal() {
    setHelpModal(<HelpModal closeModal={closeHelpModal} />);
  }

  function closeHelpModal() {
    setHelpModal(null);
  }


  // Screen components
  let helpModalElement;
  if( openHelpModal ) {
    helpModalElement = (
      <div class='header-button-container'>
        <button
          type='button'
          onClick={() => openHelpModal()}
          class='flex-row help-button'
        >
          Help <IoHelpCircleOutline size={'1.2em'} color={'#696969'} />
        </button>
      </div>
    );
  }


  return (
    <div class='left-column'>
      {helpModal}
      <div class='logo-square'></div>
      <br />
      <Link to='/dashboard' class={pathName.includes('dashboard') ? 'active' : null}>Dashboard</Link>
      <Link to='/soon' class={pathName.includes('soon') ? 'active' : null}>To Do Soon</Link>
      <Link to='/someday' class={pathName.includes('someday') ? 'active' : null}>To Do Someday</Link>
      <Link to='/history' class={pathName.includes('history') ? 'active' : null}>History</Link>
      <a href='https://tally.so/r/3ElgBA' target='_blank' rel='nofollow'>Feedback</a>
      <Link to='/settings' class={pathName.includes('settings') ? 'active' : null}>Settings</Link>

      <br />
      <br />
      <br />
      <br />
      {helpModalElement}

      {/*
      <p>Name - logout</p> */}
      <br />
      <button
        onClick={(event) => logout(event)}
        class='logout-button'
      >Logout</button>

      <div class='contact-support-container'>
        Contact support at <a href="mailto:diastandup@gmail.com">diastandup@gmail.com</a>
      </div>

    </div>
  );
}

export default LeftColumn;