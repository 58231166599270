let searchResults = [];
let searchResultCount;

function handleSearchDashboard(itemsList, searchString) {
  const length = searchString.length;

  clearHighlights();
  if( length > 3 ) {
    handleSearchValue(itemsList, searchString);
  }

  return searchResults;
}

export default handleSearchDashboard;




function clearHighlights() {
  Array.from(document.querySelectorAll('.day-item-container')).forEach(function(element) {
    element.classList.remove('highlight');
  });

  searchResults = [];
}

function handleSearchValue(itemsList, searchString) {
  const foundItems = [];
  // const foundBlockers = [];
  const regex = new RegExp(searchString, "gi");

  // Search within the days / items
  itemsList.map((day, dayIndex) => {
    day.items.map((item, itemIndex) => {
      if( item.body.match(regex)) {
        const elementId = `day-task-${dayIndex}-${itemIndex}`;
        foundItems.push(elementId);
      }
    });

    day.blockers.map((item, itemIndex) => {
      if( item.body.match(regex)) {
        const elementId = `day-blocker-${dayIndex}-${itemIndex}`;
        foundItems.push(elementId);
      }
    });
  });


  // searchResults = {foundItems: foundItems, foundBlockers, foundBlockers };
  searchResults = foundItems;
  searchResultCount = foundItems.length;
}