function LandingScreen() {

  return (
    <div class='wrapper-landing-screen'>

      <section class='flex-column hero'>

        <nav class='flex-column landing-main-nav'>
          <div class='container-primary'>
            <div class='container-links'>
                <span>BETA</span>
                <a href='/login'>Login</a>
            </div>
          </div>
        </nav>

        <div class='container-primary container-hero'>

          <div class='logo'></div>

          <h1>
            Organize your
            <br />
            <strong>Daily Standup</strong>
          </h1>

          <a href='/register'>Get Started</a>
        </div>

      </section>


      <section class='flex-column section-landing-primary'>

        <div class='flex-column container-primary'>

          <div class='flex-row'>

            <div>
              <p class='sub-heading'>Plan your day</p>
              <h2>Check 'em off as you go</h2>
            </div>

            <div class='today-image'></div>

          </div>
        </div>

      </section>


      <section class='flex-column section-landing-primary'>

        <div class='flex-column container-primary'>

          <div class='flex-row'>

          <div class='week-image'></div>

            <div>
              <p class='sub-heading'>Think ahead</p>
              <h2>Plan your week by adding tasks as you think of them</h2>
            </div>

          </div>
        </div>

      </section>


      <section class='flex-column section-landing-secondary'>

        <div class='flex-column container-primary'>

          <p>Free while in Beta</p>
          <a href='/register' class='big-action-button'>Get Started</a>

          <a
            class='secondary-link'
            href='mailto:diastandup@gmail.com'
            style={{margin: '60px 0'}}
          >diastandup@gmail.com</a>

        </div>
      </section>

    </div>
  )
}

export default LandingScreen;