let searchResults = [];
let searchResultCount;

function handleSearchSoon(itemsList, searchString) {
  const length = searchString.length;

  clearHighlights();
  if( length > 2 ) {
    handleSearchValue(itemsList, searchString);
  }

  return searchResults;
}

export default handleSearchSoon;



function clearHighlights() {
  Array.from(document.querySelectorAll('.day-item-container')).forEach(function(element) {
    element.classList.remove('highlight');
  });

  searchResults = [];
}

function handleSearchValue(itemsList, searchString) {
  const foundItems = [];
  const regex = new RegExp(searchString, "gi");

  itemsList.map((item, itemIndex) => {
    if( item.body.match(regex)) {
      const elementId = `day-task-0-${itemIndex}`;
      foundItems.push(elementId);
    }
  });

  searchResults = foundItems;
  searchResultCount = foundItems.length;
}