function SettingCheckbox(props) {

  // Define variables
  const displayName = props.displayName;
  const fieldName = props.fieldName;
  const value = props.value;
  const handleChange = props.handleChange || undefined;
  const disabled = props.disabled || false;


  return (
    <div class='flex-row settings-checkbox-container'>
      <input
        type='checkbox'
        name={fieldName}
        id={fieldName}
        checked={value}
        onChange={(event) => handleChange(!value)}
        disabled={disabled}
        class='input-basic'
      />
      <label for={fieldName}>{displayName}</label>
    </div>
  );
}

export default SettingCheckbox;