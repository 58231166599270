// pulled from https://medium.com/front-end-weekly/how-to-create-private-route-with-react-router-v6-fa4b9d74cb55

import { Navigate } from 'react-router-dom'
import { isAuthed } from "./tokenHelpers";

export const PrivateRoute = ({ component: RouteComponent }) => {
  const authedId = isAuthed();
  if (authedId) { return <RouteComponent /> }

  return <Navigate to="/register" />;
}

export default PrivateRoute;