const apiCall = async (endpoint, payload = {}) => {

  let response = {
    success: false,
    error: 'Sorry, there was an error. Please contact diastandup@gmail.com.'
  }

  try {
    const { data } = await endpoint(payload);

    if( data?.success ) {

      let result = data.data;

      // Login result is not nested
      if( data.login === 'success' ) {
        result = data;
      }

      response = {
        success: true,
        data: result
      }
    } else {
      response.error = data.error || data.errors;
    }

  } catch(error) {
    logError(error);
  } finally {
    return response;
  }

}

function logError(error) {

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    console.log('  ');
    console.log(' ---- error --- ');
    console.log(error);
    console.log(' - ');
    console.log('  ');
  }

  // TO DO - log crash report
}

export default apiCall;