import { Link } from 'react-router-dom';
import { IoCloseCircle } from "react-icons/io5";


function MoreMenu(props) {

  // Define variables
  const pathName = window.location.pathname;
  const closeMoreMenu = props.closeMoreMenu;

  return (
    <div class='flex-row more-menu-container'>
      <div class='more-menu-links-container'>

        <div class='flex-row header-links'>
          <Link to='/dashboard' class={pathName.includes('dashboard') ? 'active' : null}>Dashboard</Link>
          <Link to='/soon' class={pathName.includes('soon') ? 'active' : null}>To Do Soon</Link>
          <Link to='/someday' class={pathName.includes('someday') ? 'active' : null}>To Do Someday</Link>
          <Link to='/history' class={pathName.includes('history') ? 'active' : null}>History</Link>
          <a href='https://tally.so/r/3ElgBA' target='_blank' rel='nofollow'>Feedback</a>
          <Link to='/settings' class={pathName.includes('settings') ? 'active' : null}>Settings</Link>

          <div class='contact-support-container'>
            Contact support at <a href="mailto:diastandup@gmail.com">diastandup@gmail.com</a>
          </div>

        </div>

      </div>
      <button onClick={() => closeMoreMenu()}>
        <IoCloseCircle size={'2rem'} color={'#2D2D2D'} />
      </button>
    </div>
  );
}

export default MoreMenu;