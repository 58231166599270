import React from 'react';
import { useState, useEffect } from 'react';

import {arrayMoveImmutable} from 'array-move';
import apiCall from '../utilities/auth/apiCall';
import { getSomeday, updateSomeday } from '../utilities/auth/authApi';
import LeftColumn from '../utilities/layout/leftColumn';
import TopBar from '../utilities/layout/topBar';
import DayItem from '../dashboard/dayItem';
import LoadingBasic from '../utilities/loadingBasic';

let interval;


function SomedayScreen() {

  // Define variables
  const [loading, setLoading] = useState(<LoadingBasic />);
  const [itemsList, setItemsList] = useState([]);

  const date = new Date();
  const thisDayDate = {
    dayName: getDayName(date.getDay()),
    dayNumber: date.getDate(),
    monthName: getMonthName(date.getMonth()),
  };


  // Page load actions
  useEffect(() => {

    apiCall(getSomeday, {}).then((response) => {
      if( response.success ) {
        setItemsList(response.data);
        setLoading(null);
      } else {
        console.log('error -> ', response.error);
      }
    });

  }, []);


  // Define functions
  function handleInputChange(value, dayIndex, itemIndex, dateObject) {
    clearInterval(interval);
    const items = JSON.parse(JSON.stringify(itemsList));
    const item = items[itemIndex];
    item.body = value;

    if( !item.dayName ) {
      item.dayName = dateObject.dayName;
      item.dayNumber = dateObject.dayNumber;
      item.monthName = dateObject.monthName;
    }

    setItemsList(items);

    interval = setInterval(function(){
      autoSave();
    }, 1400);

  }

  function handleDetailChange(newItem, dayIndex, itemIndex, fieldName) {
    clearInterval(interval);

    const items = JSON.parse(JSON.stringify(itemsList));
    const item = items[itemIndex];
    item[fieldName] = newItem[fieldName];

    setItemsList(items);
    interval = setInterval(function(){
      autoSave(dayIndex);
    }, 1400);
  }

  function autoSave() {
    clearInterval(interval);

    const autosaveButton = document.querySelector(`.autosave-button`);
    autosaveButton.click();
  }

  function handleKeyDown(event, dayIndex, itemIndex) {

    if( event.code === 'Enter' ) {
      event.preventDefault();
    }

    if( event.metaKey && event.code === 'BracketRight' ) {
      event.preventDefault();
      indentItem(dayIndex, itemIndex, 'right');
    }

    if( event.metaKey && event.code === 'BracketLeft' ) {
      event.preventDefault();
      indentItem(dayIndex, itemIndex, 'left');
    }

    if( event.metaKey && event.code === 'ArrowUp' ) {
      event.preventDefault();
      moveItem(dayIndex, itemIndex, 'up');
    }

    if( event.metaKey && event.code === 'ArrowDown' ) {
      event.preventDefault();
      moveItem(dayIndex, itemIndex, 'down');
    }

  }

  function handleKeyUp(event, dayIndex, itemIndex) {
    if( event.code === 'Enter' ) {
      const items = JSON.parse(JSON.stringify(itemsList));
      const insertAt = itemIndex + 1;

      const newItems = [
        // Items before the insertion point:
        ...items.slice(0, insertAt),
        // New item:
        { body: '', complete: false, intentIndex: 0 },
        // Items after the insertion point:
        ...items.slice(insertAt)
      ];

      setItemsList(newItems);

      // Auto foucs
      setTimeout(() => {
        // const itemElementId = `day-item-0-${itemIndex+1}`;
        const itemElementId = `id-0-${itemIndex+1}-textarea`;
        // const newInput = document.querySelector(`#${itemElementId} textarea`);
        const newInput = document.querySelector(`#${itemElementId}`);
        newInput.focus();
      }, 50);

    }

  }

  function indentItem(dayIndex, itemIndex, direction) {

    if( itemIndex === 0 && direction === 'right' ) {
      return false;
    }

    const items = JSON.parse(JSON.stringify(itemsList));
    const item = items[itemIndex];
    let currentIndentIndex = item.intentIndex;
    if( currentIndentIndex === undefined ) { currentIndentIndex = 0 }
    const newIndentIndex = (direction === 'right') ? currentIndentIndex + 1 : currentIndentIndex - 1;

    item.intentIndex = newIndentIndex;
    setItemsList(items);

    setTimeout(() => {
      autoSave(dayIndex);
    }, 50);
  }

  function moveItem(dayIndex, itemIndex, direction) {

    if( itemIndex === 0 && direction === 'up' ) {
      return false;
    }

    const items = JSON.parse(JSON.stringify(itemsList));

    if( itemIndex === (items.length - 1) && direction === 'down' ) {
      return false;
    }

    const newItemIndex = direction === 'up' ? itemIndex - 1 : itemIndex + 1;
    const newItems = arrayMoveImmutable(items, itemIndex, newItemIndex);
    setItemsList(newItems);

    setTimeout(() => {
      autoSave(dayIndex);
      const itemElementId = `day-task-0-${newItemIndex}`;
      const newInput = document.querySelector(`#${itemElementId} textarea`);
      newInput.focus();

    }, 50);
  }

  function deleteItem(dayIndex, itemIndex) {
    const items = JSON.parse(JSON.stringify(itemsList));

    if( items.length === 1 ) {
      return false;
    }

    items.splice(itemIndex, 1);

    setTimeout(() => {
      setItemsList(items);
      setTimeout(() => autoSave(dayIndex), 50);
    }, 50);
  }

  function handleCheckboxChange(dayIndex, itemIndex) {
    const items = JSON.parse(JSON.stringify(itemsList));
    const item = items[itemIndex];
    const complete = item.complete ? false : true;

    item.complete = complete;
    setItemsList(items);

    setTimeout(() => {
      autoSave(dayIndex);
    }, 50);
  }


  function updateContent() {

    const payload = {someday_items: itemsList};

    apiCall(updateSomeday, payload).then((response) => {
      if( response.success ) {
        // Do nothing
      } else {
        console.log('error -> ', response.error);
      }
    });
  }

  // function pushToNextDay() {
  //   // Do nothing
  // }



  return (
    <div class='wrapper'>

      <LeftColumn />

      <div class='right-column'>

        <TopBar
          searchType={'soon'}
          itemsList={itemsList}
        />

        <section class='body-content to-do-soon'>
          <div class='left-bar'>
            <h2>Items To Do Someday</h2>
            {loading}
            {itemsList.map((item, itemIndex) =>
              <DayItem
                key={`item-${itemIndex}`}
                item={item}
                itemIndex={itemIndex}
                dayIndex={0}
                handleInputChange={handleInputChange}
                handleDetailChange={handleDetailChange}
                handleKeyUp={handleKeyUp}
                handleKeyDown={handleKeyDown}
                indentItem={indentItem}
                moveItem={moveItem}
                // pushToNextDay={pushToNextDay}
                deleteItem={deleteItem}
                handleCheckboxChange={handleCheckboxChange}
                itemsLength={itemsList.length}
                thisDayDate={thisDayDate}
              />
            )}
          </div>

          <button type='button' class='autosave-button' onClick={() => updateContent()}>Save</button>
        </section>

        </div>
    </div>
  );
}

export default SomedayScreen;


// --

function getDayName(number) {
  let name;

  switch(number) {
    case 0:
      name = 'Saturday';
      break;
    case 1:
      name = 'Sunday';
      break;
    case 2:
      name = 'Monday';
      break;
    case 3:
      name = 'Tuesday';
      break;
    case 4:
      name = 'Wednesday';
      break;
    case 5:
      name = 'Thursday';
      break;
    case 6:
      name = 'Friday';
      break;
    default:
      // code block
  }

  return name;
}

function getMonthName(number) {
  let name;

  switch(number) {
    case 0:
      name = 'January';
      break;
    case 1:
      name = 'February';
      break;
    case 2:
      name = 'March';
      break;
    case 3:
      name = 'April';
      break;
    case 4:
      name = 'May';
      break;
    case 5:
      name = 'June';
      break;
    case 6:
      name = 'July';
      break;
    case 7:
      name = 'August';
      break;
    case 8:
      name = 'September';
      break;
    case 9:
      name = 'October';
      break;
    case 10:
      name = 'November';
      break;
    case 11:
      name = 'December';
      break;
    default:
      // code block
  }

  return name;
}