import { useLocation } from "react-router-dom";
import DayItem from "./dayItem";

import { ReactSortable } from "react-sortablejs";


function Day(props) {

  // Define variables
  let sortedList;
  const location = useLocation();
  const pathname = location.pathname;
  const disabled = props.disabled;
  const day = props.day;
  const dayIndex = props.dayIndex;
  const handleInputChange = props.handleInputChange;
  const handleDetailChange = props.handleDetailChange;
  const handleKeyUp = props.handleKeyUp;
  const handleKeyDown = props.handleKeyDown;
  const addNewRow = props.addNewRow;
  const indentItem = props.indentItem;
  const moveItem = props.moveItem;
  const sortItem = props.sortItem;
  const pushToNextDay = props.pushToNextDay;
  const deleteItem = props.deleteItem;
  const handleCheckboxChange = props.handleCheckboxChange;
  const updateContent = props.updateContent;
  const today = day.today;
  const tomorrow = day.tomorrow;

  function handleSort(data) {
    sortedList = data;
  }

  function onEnd() {
    sortItem(dayIndex, 'item', sortedList);
  }

  let wrapperId;
  const containerId = `day-${dayIndex}`;

  if( today ) {
    wrapperId = 'today';
  }

  if( tomorrow ) {
    wrapperId = 'tomorrow';
  }


  const date = new Date(day.date);
  const thisDayDate = {
    dayName: getDayName(date.getDay()),
    dayNumber: date.getDate(),
    monthName: getMonthName(date.getMonth()),
  };

  let addMoreTaskElement;
  let addMoreBlockerElement;
  if( pathname === '/dashboard' ) {
    addMoreTaskElement = (
      <button
        class='button add-line-button'
        type='button'
        title='Tap to add a new row'
        onClick={() => addNewRow(dayIndex, (day.items.length - 1), 'task')}
      >
        Add new row
      </button>
    );

    addMoreBlockerElement = (
      <button
        class='button add-line-button'
        type='button'
        title='Tap to add a new row'
        onClick={() => addNewRow(dayIndex, (day.blockers.length - 1), 'blocker')}
      >
        Add new row
      </button>
    );
  }

  let dayHeader;
  if( today ) {
    dayHeader = (
      <div class='day-header'>
        <div class='inner'>
          <h2>Today</h2>
          <p>{day.date}</p>
        </div>
        <div class='color-block'></div>
      </div>
    );
  } else {
    dayHeader = (
      <div class='day-header'>
        <div class='inner'>
          <h3>{day.date}</h3>
        </div>
        <div class='color-block'></div>
      </div>
    );
  }


  return(
    <div id={wrapperId}>
      <div
        id={containerId}
        class='day-container'
      >

        {dayHeader}

        <div class={`day-tasks ${day.items.length <= 1 ? 'only-one-item' : ''}`}>
          <h3>Tasks</h3>

          <ReactSortable
            list={day.items}
            setList={handleSort}
            onEnd={onEnd}
            animation={200}
            ghostClass={'sortable-ghost'}
            dragClass={'sortable-drag'}
            direction={'verticle'}
            handle={'.drag-handle'}
            // delayOnTouchStart={true}
            // delay={2}
          >
            {day.items.map((item, itemIndex) =>
              <DayItem
                key={`item-${itemIndex}`}
                id={item.id}
                item={item}
                itemIndex={itemIndex}
                dayIndex={dayIndex}
                handleInputChange={handleInputChange}
                handleDetailChange={handleDetailChange}
                handleKeyUp={handleKeyUp}
                handleKeyDown={handleKeyDown}
                indentItem={indentItem}
                moveItem={moveItem}
                pushToNextDay={pushToNextDay}
                deleteItem={deleteItem}
                handleCheckboxChange={handleCheckboxChange}
                itemsLength={day.items.length}
                thisDayDate={thisDayDate}
                disabled={disabled}
                today={today}
              />
            )}
          </ReactSortable>
          {addMoreTaskElement}
        </div>


        <div class='day-blockers'>
          <h3>Blockers</h3>
          {day.blockers.map((item, itemIndex) =>
            <DayItem
              key={`item-${itemIndex}`}
              item={item}
              itemIndex={itemIndex}
              dayIndex={dayIndex}
              handleInputChange={handleInputChange}
              handleKeyUp={handleKeyUp}
              handleKeyDown={handleKeyDown}
              indentItem={indentItem}
              moveItem={moveItem}
              pushToNextDay={pushToNextDay}
              deleteItem={deleteItem}
              handleCheckboxChange={handleCheckboxChange}
              itemsLength={day.blockers.length}
              thisDayDate={thisDayDate}
              disabled={disabled}
              itemType={'blocker'}
              today={today}
            />
          )}
          {addMoreBlockerElement}
        </div>

        <button type='button' class='autosave-button' onClick={() => updateContent(dayIndex)}>Save</button>

      </div>
    </div>
  );
}

export default Day;


// --

function getDayName(number) {
  let name;

  switch(number) {
    case 0:
      name = 'Saturday';
      break;
    case 1:
      name = 'Sunday';
      break;
    case 2:
      name = 'Monday';
      break;
    case 3:
      name = 'Tuesday';
      break;
    case 4:
      name = 'Wednesday';
      break;
    case 5:
      name = 'Thursday';
      break;
    case 6:
      name = 'Friday';
      break;
    default:
      // code block
  }

  return name;
}

function getMonthName(number) {
  let name;

  switch(number) {
    case 0:
      name = 'January';
      break;
    case 1:
      name = 'February';
      break;
    case 2:
      name = 'March';
      break;
    case 3:
      name = 'April';
      break;
    case 4:
      name = 'May';
      break;
    case 5:
      name = 'June';
      break;
    case 6:
      name = 'July';
      break;
    case 7:
      name = 'August';
      break;
    case 8:
      name = 'September';
      break;
    case 9:
      name = 'October';
      break;
    case 10:
      name = 'November';
      break;
    case 11:
      name = 'December';
      break;
    default:
      // code block
  }

  return name;
}