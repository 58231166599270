import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import apiCall from '../utilities/auth/apiCall';
import { login } from '../utilities/auth/authApi';
import { isAuthed, saveToken } from "../utilities/auth/tokenHelpers";
import FormErrorMessage from '../utilities/formErrorMessage';


function Login() {

  // Define variables
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');


  // Page load actions
  useEffect(() => {

    // Redirect if already logged in
    const isLoggedIn = isAuthed();
    if( isLoggedIn ) {
      navigate("/dashboard");
    }

  }, []);


  // Define functions
  function callLogin(event) {
    event.preventDefault();

    const payload = {
      email: email,
      password: password
    }

    apiCall(login, payload).then((response) => {
      if( response.success ) {
        saveToken(response.data.jwt);
        navigate("/dashboard");
      } else {
        console.log('error -> ', response.error);
        setErrorMessage(response.error);
      }
    });
  }


  return(
    <div class='flex-column' style={{height: '100vh'}}>

      <header class='flex-row'>
        <div class='logo-square'></div>
        <h1>Dia Daily Standup</h1>
      </header>

      <section class='body-content'>

        <h1>Login</h1>

        <FormErrorMessage message={errorMessage} />

        <form class='form' onSubmit={(event) => callLogin(event)}>

          <div class='input-label'>
            <input
              type='text'
              onChange={(event) => setEmail(event.target.value)}
              value={email}
              placeholder='Email address'
              required
            />
          </div>

          <div class='input-label'>
            <input
              type='password'
              onChange={(event) => setPassword(event.target.value)}
              value={password}
              placeholder='Password'
              required
            />
          </div>

          <div class='input-label'>
            <button type='submit' class='button'>Login</button>
          </div>

        </form>

        <br /><br /><br />
        <Link to='/forgot_password' class='sub-text'>Forgot Password</Link>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>|<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <Link to='/register' class='sub-text'>Need an account? Register</Link>
      </section>
    </div>
  );
}

export default Login;