import { useState, useEffect, useRef } from 'react';


function ItemTextarea(props) {

  // Define variables
  const textAreaRef = useRef(props.value);
  const [rows, setRows] = useState(1)
  const disabled = props.disabled;
  const itemType = props.itemType;
  const dayIndex = props.dayIndex;
  const itemIndex = props.itemIndex;
  const thisDayDate = props.thisDayDate;
  const handleInputChange = props.handleInputChange;
  const handleKeyUp = props.handleKeyUp;
  const handleKeyDown = props.handleKeyDown;
  const value = props.value;

  const id = `id-${dayIndex}-${itemIndex}-textarea`;


  // Page load actions
  useEffect(() => {
    setTextareaRows();
  }, [value]);


  // Define functions
  function setTextareaRows() {
    const scrollWidth = textAreaRef.current.scrollWidth;
    let divider = 6.3;

    if( scrollWidth < 200 ) {
      divider = 7;
    } else if( scrollWidth < 300 ) {
      divider = 6.9;
    } else if( scrollWidth < 400 ) {
      divider = 6.7;
    } else if( scrollWidth < 500 ) {
      divider = 6.5;
    }


    const characterCount = scrollWidth / divider;
    let newRows = Math.ceil(value.length / characterCount);
    if( newRows === 0 ) { newRows = 1 }
    setRows(newRows);
  }


  return (
    <textarea
      ref={textAreaRef}
      id={id}
      onChange={(event) => handleInputChange(event.target.value, dayIndex, itemIndex, thisDayDate, itemType)}
      onKeyUp={(event) => handleKeyUp(event, dayIndex, itemIndex, itemType)}
      onKeyDown={(event) => handleKeyDown(event, dayIndex, itemIndex, itemType) }
      value={value}
      rows={rows}
      disabled={disabled}
      placeholder='Add details ...'
    >
    </textarea>
  );
}

export default ItemTextarea;