import chroma from 'chroma-js';
import ItemTextarea from "../utilities/itemTextarea";
import { IoCheckmark, IoReorderFourOutline } from "react-icons/io5";
import EditTaskButton from "../utilities/editTaskButton";


function DayItem(props) {

  // Define variables
  const disabled = props.disabled;
  const itemType = props.itemType || 'task';
  const item = props.item;
  const itemIndex = props.itemIndex;
  const dayIndex = props.dayIndex;
  const today = props.today;

  const handleInputChange = props.handleInputChange;
  const handleDetailChange = props.handleDetailChange;
  const handleKeyUp = props.handleKeyUp;
  const handleKeyDown = props.handleKeyDown;
  const pushToNextDay = props.pushToNextDay;
  const deleteItem = props.deleteItem;
  const handleCheckboxChange = props.handleCheckboxChange;

  // const completeIcon = <IoCheckmark color={'#696969'} style={{backgroundColor: '#f3f3f3', borderRadius: '10px'}} />;
  const completeIcon = <IoCheckmark color={'#696969'} style={{backgroundColor: '#DBFFF9', width: '14px', height: '14px', borderRadius: '10px'}} />;
  const dragHandle = <IoReorderFourOutline color={'#2D2D2D'} />;
  // const pushIcon = <IoChevronUp color={'#696969'} />;
  // const deleteIcon = <IoTrashOutline color={'#696969'} />;

  const intentIndex = item.intentIndex;
  const complete = item.complete;

  const thisDayDate = props.thisDayDate;
  const dayName = item.dayName;
  const dayNumber = item.dayNumber || 100;
  // const monthName = item.monthName;

  const dueDate = item.dueDate;
  const labels = item.labels;
  const link = item.link;

  let indentDistance = 0;
  if( intentIndex > 0 ) {
    indentDistance = intentIndex * 30;
  }

  let checkboxIcon;
  if( complete ) {
    checkboxIcon = completeIcon;
  }


  // let indentElements = <span class='item-action-button-indent-spacer'></span>;
  // let moveUpElement = <span class='item-action-button-spacer'></span>;
  // let moveDownElement = <span class='item-action-button-spacer'></span>;

  // if( itemIndex > 0 ) {
  //   indentElements = (
  //     <div class='flex-row'>

  //       <button
  //         type='button'
  //         class='item-action-button'
  //         onClick={() => indentItem(dayIndex, itemIndex, 'left')}
  //       >{indentLeftIcon}</button>

  //       <button
  //         type='button'
  //         class='item-action-button'
  //         onClick={() => indentItem(dayIndex, itemIndex, 'right')}
  //       >{indentRightIcon}</button>

  //     </div>
  //   );

  //   moveUpElement = (
  //     <button
  //       type='button'
  //       class='item-action-button'
  //       onClick={() => moveItem(dayIndex, itemIndex, 'up')}
  //     >{moveUpIcon}</button>
  //   );
  // }

  // if( itemIndex < itemsLength ) {
  //   moveDownElement = (
  //     <button
  //       type='button'
  //       class='item-action-button'
  //       onClick={() => moveItem(dayIndex, itemIndex, 'down')}
  //     >{moveDownIcon}</button>
  //   );
  // }


  // let pushToNextDayElement = <span class='push-spacer'></span>;
  // if( itemType === 'task' && item?.body?.length > 0 && !complete && pushToNextDay ) {
  //   pushToNextDayElement = (
  //     <button
  //       type='button'
  //       class='item-action-button-secondary push-button'
  //       title='Push item to next day'
  //       onClick={() => pushToNextDay(dayIndex, itemIndex)}
  //     >Push {pushIcon}</button>
  //   );
  // }

  // let deleteItemElement;
  // if( item?.body?.length === 0 && itemIndex !== 0 ) {
  //   pushToNextDayElement = null;
  //   deleteItemElement = (
  //     <button
  //       type='button'
  //       class='item-action-button-secondary delete-button'
  //       title='Remove line'
  //       onClick={() => deleteItem(dayIndex, itemIndex, itemType)}
  //     >{deleteIcon}</button>
  //   );
  // }

  let checkboxElement;
  if( itemType === 'task' ) {
    checkboxElement = (
      <button
        type='button'
        class='checkbox-button flex-column'
        onClick={() => handleCheckboxChange(dayIndex, itemIndex)}
        disabled={disabled}
      >{checkboxIcon}</button>
      // <button
      //   type='button'
      //   class={`checkbox-button flex-column ${complete ? 'checked' : ''}`}
      //   onClick={() => handleCheckboxChange(dayIndex, itemIndex)}
      //   disabled={disabled}
      // >{checkboxIcon}</button>
    );
  }

  let editElement;
  if( itemType === 'task' ) {
    editElement = (
      <EditTaskButton
        item={item}
        dayIndex={dayIndex}
        itemIndex={itemIndex}
        itemType={itemType}
        complete={complete}
        pushToNextDay={pushToNextDay}
        deleteItem={deleteItem}
        handleDetailChange={handleDetailChange}
        today={today}
      />
    );
  }


  let showExtras = false;
  let extrasElement;
  let createdAtElement;
  let dueDateElement;
  let labelsElement;
  let linkElement;

  // Created at element
  if( thisDayDate.dayNumber !== dayNumber && dayNumber !== 100 && item?.body?.length !== 0 ) {
    createdAtElement = <div class='created-at-date'>Created {dayName} {dayNumber}{nthNumber(dayNumber)}</div>;
    showExtras = true;
  }

  // Due date element
  if( dueDate ) {
    // const b = new Date(a);
    // const c = b.toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"long", day:"numeric"})
    dueDateElement = <div class='due-date'>Due: {dueDate}</div>;
    showExtras = true;
  }

  // Labels eleemnt
  if( labels ) {
    labelsElement = (
      <div class='flex-row'>
        {labels.map((label, index) =>
          <div class='item-label' style={{
              color: chroma(label.color).darken(1),
              borderColor: label.color,
              backgroundColor: chroma(label.color).alpha(0.1)
            }}>
            <span class='item-label-dot' style={{backgroundColor: label.color}}></span>
            <span class='item-label-name'>{label.value}</span>
          </div>
        )}
      </div>
    );

    showExtras = true;
  }

  // Link
  if( link ) {
    showExtras = true;

    linkElement = (
      <div class='item-link-continer'>
        <a href={formattedLink(link)} target='_blank' rel='nofollow' title='Open link in new tab'>{truncate(link)}</a>
      </div>
    );
  }


  // Extras element
  if( showExtras ) {
    extrasElement = (
      <div class='flex-row day-item-extras-container'>
        <div class='flex-row'>
          {createdAtElement}
          {dueDateElement}
          {linkElement}
        </div>

        {labelsElement}
      </div>
    );
  }


  return (
    <div
      class={`day-item-container ${item.body.length > 0 ? '' : 'no-text'} ${complete ? 'complete' : ''}`}
      id={`day-${itemType}-${dayIndex}-${itemIndex}`}
    >
      <div class='day-item-container-inner' style={{marginLeft: indentDistance}}>
        <div class='flex-row'>

          <div class='flex-row drag-handle'>
            {dragHandle}
          </div>

          {checkboxElement}

          <ItemTextarea
            dayIndex={dayIndex}
            itemIndex={itemIndex}
            thisDayDate={thisDayDate}
            itemType={itemType}
            handleInputChange={handleInputChange}
            handleKeyUp={handleKeyUp}
            handleKeyDown={handleKeyDown}
            value={item.body}
            disabled={disabled}
          />

          {/* {pushToNextDayElement}
          {deleteItemElement} */}
          {editElement}
        </div>

        {extrasElement}
      </div>
    </div>
  );
}

export default DayItem;


// --

const nthNumber = (number) => {
  if (number > 3 && number < 21) return "th";
  switch (number % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};


function truncate(string) {
  return string.length > 35 ? string.substring(0, 32) + "..." : string;
}

function formattedLink(string) {
  // Trim any leading or trailing whitespace from the string
  let formatted = string.trim();

  // Remove 'www.' if present
  if (formatted.startsWith('www.')) {
      formatted = formatted.substring(4);
  }

  // Add 'https://' if it's not already there
  if (!formatted.startsWith('http://') && !formatted.startsWith('https://')) {
      formatted = 'https://' + formatted;
  }

  return formatted;
}