import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import './App.css';

import PrivateRoute from "./components/utilities/auth/privateRoute";

import LandingScreen from './components/start/landingScreen';
import Register from './components/start/register';
import Login from './components/start/login';

import ForgotPassword from './components/start/forgotPassword';
import ResetPassword from './components/start/resetPassword';

import DashboardScreen from './components/dashboard';
import SoonScreen from './components/soon';
import SomedayScreen from './components/someday';
import HistoryScreen from './components/history';
import SettingsScreen from './components/settings';



function App() {
  return (
    <BrowserRouter>
      <RoutesF />
    </BrowserRouter>
  );
}

function RoutesF() {
  return (
    <Routes>

      <Route path="/" element={<LandingScreen />} />
      <Route path="register" element={<Register />} />
      <Route path="login" element={<Login />} />

      <Route path="forgot_password" element={<ForgotPassword />} />
      <Route path="reset_password" element={<ResetPassword />} />

      {/* <Route path="/" element={<PrivateRoute component={DashboardScreen} />} /> */}
      <Route path="/dashboard" element={<PrivateRoute component={DashboardScreen} />} />
      <Route path="/soon" element={<PrivateRoute component={SoonScreen} />} />
      <Route path="/someday" element={<PrivateRoute component={SomedayScreen} />} />
      <Route path="/history" element={<PrivateRoute component={HistoryScreen} />} />
      <Route path="/settings" element={<PrivateRoute component={SettingsScreen} />} />

    </Routes>
  );

}

export default App;