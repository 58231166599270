function FormErrorMessage(props) {

  // Define functions
  const message = props.message;


  return (
    <div class='error-messsage-conainer'>
      <p class='error'>{message}</p>
    </div>
  );
}

export default FormErrorMessage;