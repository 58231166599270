function SettingSelect(props) {

  // Define variables
  const displayName = props.displayName;
  const fieldName = props.fieldName;
  const value = props.value;
  const options = props.options;
  const handleChange = props.handleChange || undefined;
  const disabled = props.disabled || false;


  return (
    <div class='flex-row settings-field-container'>
      <div>{displayName}</div>
      <div style={{width: '100%', maxWidth: '400px'}}>
        <select
          name={fieldName}
          id={fieldName}
          onChange={(event) => handleChange(event.target.value)}
          disabled={disabled}
          class='select-basic'
        >
          <option>Select</option>
          {options.map((item, index) =>
              <option
                key={`select-${fieldName}-${index}`}
                value={item[0]}
                selected={value === item[0]}
              >
                {item[1]}
              </option>
            )}
        </select>
      </div>
    </div>
  );
}

export default SettingSelect;