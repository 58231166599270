import React from "react";
import { useState } from "react";

import { IoCloseCircle } from "react-icons/io5";
import { IoCopy } from "react-icons/io5";


function FormattedStandup(props) {

  // Define variables
  const [loading, setLoading] = useState(false);
  const standup = props.standup;
  const closeModal = props.closeModal;


  // Define functions
  function selectText() {
    setLoading(true);

    const element = document.querySelector('.standup-text-container');

    const range = document.createRange();
    range.selectNode(element);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }


  // Screen Components
  let copyText = (
    <div>
      Copy Daily Standup&nbsp;&nbsp;
      <IoCopy size={'1rem'} color={'#2D2D2D'} />
    </div>
  );

  if( loading ) {
    copyText = (
      <div>
        Copying ...
      </div>
    );
  }


  return (
    <div class='modal-wrapper'>
      <div class='modal-container'>
        <div>

          <div class='flex-row' style={{justifyContent: 'space-between', marginBottom: '30px'}}>
            <div style={{width: '30px'}}>&nbsp;</div>

            <h3>Standup</h3>

            <button
              type='button'
              class=''
              onClick={() => closeModal()}
            >
              <IoCloseCircle size={'1.5rem'} color={'#2D2D2D'} />
            </button>
          </div>

          <div class='flex-column' style={{marginBottom: '45px'}}>
            <button
              type='button'
              class='button'
              onClick={() => selectText()}
              style={{minWidth: '200px', height: '45px'}}
            >
              {copyText}
            </button>
          </div>

          <div class='standup-text-container'>{standup}</div>

        </div>
      </div>
    </div>
  )
}

export default FormattedStandup;