import { useState, useEffect } from 'react';

import apiCall from '../utilities/auth/apiCall';
import { getProfile, updateUser } from '../utilities/auth/authApi';
import LeftColumn from '../utilities/layout/leftColumn';
import TopBar from '../utilities/layout/topBar';
import LoadingBasic from '../utilities/loadingBasic';
import SettingField from '../utilities/settingField';
import SettingCheckbox from '../utilities/settingsCheckbox';
import timezoneList from '../utilities/timezoneList';
import SettingSelect from '../utilities/settingsSelect';


function SettingsScreen() {

  // Define variables
  const [loading, setLoading] = useState(<LoadingBasic />);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [firstName, setFirstName] = useState('Loading ...');
  const [lastName, setLastName] = useState('Loading ...');
  const [email, setEmail] = useState('Loading ...');
  const [timezone, setTimezone] = useState('Loading ...');
  const [preferenceEmailMonday, setPreferenceEmailMonday] = useState(false);
  const [preferenceEmailWednesday, setPreferenceEmailWednesday] = useState(false);
  const [preferenceEmailFriday, setPreferenceEmailFriday] = useState(false);
  // const [preferenceShowTaskStartDate, setPreferenceShowTaskStartDate] = useState(false);


  // Page load actions
  useEffect(() => {

    apiCall(getProfile, {}).then((response) => {
      if( response.success ) {

        setFirstName(response.data.firstName);
        setLastName(response.data.lastName);
        setEmail(response.data.email);
        setTimezone(response.data.timezone);
        setPreferenceEmailMonday(response.data.preferenceEmailMonday);
        setPreferenceEmailWednesday(response.data.preferenceEmailWednesday);
        setPreferenceEmailFriday(response.data.preferenceEmailFriday);
        // setPreferenceShowTaskStartDate(response.data.preferenceShowTaskStartDate);
        setLoading(false);

      } else {
        // console.log('error -> ', response.error);
      }
    });

  }, []);


  // Define functions
  function handleSubmit() {
    setButtonLoading(true);
    setMessage(null);

    const paylaod = {
      user: {
        preference_email_monday: preferenceEmailMonday,
        preference_email_wednesday: preferenceEmailWednesday,
        preference_email_friday: preferenceEmailFriday,
        timezone: timezone
      }
    };

    apiCall(updateUser, paylaod).then((response) => {
      if( response.success ) {
        // Do nothing
        setTimeout(() => {
          setMessage('Setting successfully saved');
        }, 1500);
      } else {
        alert(response.error);
      }
    }).finally(() => {
      setTimeout(() => {
        setButtonLoading(false);
      }, 2000);
    });

  }



  return (
    <div class='wrapper'>

      <LeftColumn />

      <div class='right-column'>

        <TopBar />

        <section class='body-content to-do-soon'>
          <div class='left-bar'>
            <h2>Settings</h2>
            {loading}

            <div>

              <SettingField
                displayName={'First Name'}
                value={firstName}
                disabled={true}
              />

              <SettingField
                displayName={'Last Name'}
                value={lastName}
                disabled={true}
              />

              <SettingField
                displayName={'Email'}
                value={email}
                disabled={true}
              />

              <SettingSelect
                displayName={'Timezone'}
                fieldName={'timezone'}
                value={timezone}
                options={timezoneList()}
                handleChange={setTimezone}
              />

              <br />
              <br />
              <h3>Reminder Emails</h3>

              <SettingCheckbox
                displayName={'Monday Email'}
                fieldName={'preferenceEmailMonday'}
                value={preferenceEmailMonday}
                handleChange={setPreferenceEmailMonday}
              />

              <SettingCheckbox
                displayName={'Wednesday Email'}
                fieldName={'preferenceEmailWednesday'}
                value={preferenceEmailWednesday}
                handleChange={setPreferenceEmailWednesday}
              />

              <SettingCheckbox
                displayName={'Friday Email'}
                fieldName={'preferenceEmailFriday'}
                value={preferenceEmailFriday}
                handleChange={setPreferenceEmailFriday}
              />

              <p class='settings-message'>{message}</p>

              <div style={{marginTop: '15px'}}>
                <button
                  class='button'
                  onClick={() => handleSubmit()}
                  disabled={buttonLoading}
                >Save</button>
              </div>

            </div>

          </div>

        </section>

      </div>
    </div>
  );
}

export default SettingsScreen;