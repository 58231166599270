import React from 'react';
import { useState, useEffect } from 'react';

import apiCall from '../utilities/auth/apiCall';
import { getHistory } from '../utilities/auth/authApi';
import LeftColumn from '../utilities/layout/leftColumn';
import TopBar from '../utilities/layout/topBar';
import Day from '../dashboard/day';
import LoadingBasic from '../utilities/loadingBasic';


function HistoryScreen() {

  // Define variables
  const [loading, setLoading] = useState(<LoadingBasic />);
  const [page, setPage] = useState(1);
  const [itemsListFull, setItemsListFull] = useState([]);
  const [itemsList, setItemsList] = useState([]);


  // Page load actions
  useEffect(() => {

    const payload = {
      page: page
    }

    apiCall(getHistory, payload).then((response) => {
      if( response.success ) {
        setItemsListFull(response.data);
        setItemsList(response.data[0]);
        setLoading(null);
      } else {
        console.log('error -> ', response.error);
      }
    });

  }, []);


  // Define functions
  function changePage(page) {
    setPage(page);

    const newItemsList = itemsListFull[page - 1];
    setItemsList(newItemsList);
  }


  return (
    <div class='wrapper'>

      <LeftColumn />

      <div class='right-column'>

        <TopBar
          // searchType={'dashboard'}
          itemsList={itemsListFull}
        />

        <section class='body-content history'>
          <div class='left-bar'>
            {loading}
            {itemsList.map((day, dayIndex) =>
              <Day
                key={`day-${dayIndex}`}
                day={day}
                dayIndex={dayIndex}
                disabled={true}
              />
            )}
            {/* {itemsListFull.map((week, weekIndex) =>
              <div class='history-week-container' key={`history-week-${weekIndex}`}>
                <h4>{week[week.length - 1].date} - {week[0].date}</h4>
                {week.map((day, dayIndex) =>
                  <Day
                    key={`day-${dayIndex}`}
                    day={day}
                    dayIndex={dayIndex}
                    disabled={true}
                  />
                )}
              </div>
            )} */}
          </div>
        </section>

        <div class='flex-column history-wrapper'>
          <div class='flex-row history-container'>
          {[...Array(10)].map((x, i) =>
            <button
              type='button'
              class={`button ${i+1 === page ? 'active' : null}`}
              onClick={() => changePage(i+1)}
            >
              {i+1}
            </button>
          )}
          </div>
        </div>

      </div>
    </div>
  );
}

export default HistoryScreen;