import { useState } from 'react';
import { IoEye } from "react-icons/io5";
import { IoToday } from "react-icons/io5";
import { IoMenuOutline } from "react-icons/io5";

import MoreMenu from "./moreMenu";
import SearchBar from '../searchBar';


function TopBar(props) {

  // Define variables
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const openStandupModal = props.openStandupModal;
  const scrollToToday = props.scrollToToday;
  const itemsList = props.itemsList;
  const searchType = props.searchType;
  let showLinks = true


  // Define functions
  function closeMoreMenu() {
    setShowMoreMenu(false);
  }


  // Screen components
  let moreMenuElement;
  if( showMoreMenu ) {
    moreMenuElement = <MoreMenu closeMoreMenu={closeMoreMenu} />;
  }

  let standupElement;
  if( openStandupModal ) {
    standupElement = (
      <button
        onClick={() => openStandupModal()}
        title='View daily standup'
        class='flex-row button'
        id='view-daily-standup'
      >
        <IoEye size={'1.25em'} color={"#2D2D2D"} />
        Standup
      </button>
    );
  }

  let todayElement;
  if( scrollToToday ) {
    todayElement = (
      <button
        onClick={() => scrollToToday()}
        title='View daily standup'
        class='flex-row button'
      >
        <IoToday size={'1.25em'} color={"#2D2D2D"} />
        Today
      </button>
    );
  }

  let searchElement;
  if( searchType ) {
    searchElement = <SearchBar itemsList={itemsList} searchType={searchType} />;
  }


  return (
    <div class='top-bar'>

      {moreMenuElement}
      <div class='flex-row top-bar-mobile'>
        <div class='logo-square'></div>
        <button
          class='nav-more-menu'
          type='button'
          onClick={() => setShowMoreMenu(!showMoreMenu)}
          title='Open menu'
        >
          <IoMenuOutline size={'1.5em'} color={"#2D2D2D"} />
        </button>

      </div>

      <div class='flex-row top-bar-inner'>

          <div class='flex-row top-bar-buttons-container' style={{flexShrink: 0}}>
            {standupElement}
            {todayElement}
          </div>

          <div class='search-bar-container'>
            {searchElement}
          </div>

        </div>

    </div>
  );
}

export default TopBar;